.RBCSW__cta{
    /* border: 1px solid white; */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}

.RBCSW__cta-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #0E0E0E;
}

.RBCSW__cta-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    color: #000000;
}

.RBCSW__cta-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.RBCSW__cta-btn button{
    background: #000000;
    color: #FFFFFF;
    border-radius: 40px;
    font-family:  var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media  screen and (max-width:650px) {
 .RBCSW__cta{
    flex-direction: column;
 }   

 .RBCSW__cta-btn{
    margin: 2rem 0 0;
 }   
}

@media screen and (max-width:550px){
    .RBCSW__cta{
        margin: 4rem 2rem;
    }

    .RBCSW__cta-content h3{
        font-size: 18px;
        line-height: 32px;
    }

    .RBCSW__cta-content button{
        font-size: 14px;
        line-height: 28px;
    }
}